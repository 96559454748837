import Swiper, { Pagination, Autoplay } from 'swiper';
import { debounce } from 'underscore';
import researchList from './research.json';

(() => {
  const isSwiper = !!document.querySelectorAll('.swiper').length;
  const currentYear = new Date().getFullYear();

  /* GET RANDOM NUMBERS FUNCTION */
  const getRandomUniqueNumbers = (min, max, count) => {
    if (count > max - min + 1) {
      throw new Error('The number of requested numbers exceeds the possible range');
    }
    const uniqueNumbers = new Set();
    while (uniqueNumbers.size < count) {
      const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      uniqueNumbers.add(randomNumber);
    }
    return Array.from(uniqueNumbers);
  };

  if (isSwiper) {
    const swiper = new Swiper('.swiper', {
      modules: [Pagination, Autoplay],
      // Optional parameters

      // If we need pagination
      slidesPerView: 1,
      spaceBetween: 16,
      autoHeight: true,
      autoplay: {
        delay: 100000000000000,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
          autoHeight: false,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
    });
  }

  /* ================================================================================================
   *                                     IS TOUCH DETECTION
   * ================================================================================================= */

  if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    // touch-device
    document.documentElement.classList.add('touch-device');
    document.querySelectorAll('header .header__navigation .navigation__item.sub').forEach((el) => {
      el.addEventListener('click', () => {
        el.classList.toggle('open');
      });
    });

    // Close on click outside elemet
    document.addEventListener('click', (event) => {
      document.querySelectorAll('header .header__navigation .navigation__item.sub').forEach((el) => {
        if (!el.contains(event.target)) {
          el.classList.remove('open');
        }
      });
      if (!document.querySelector('header').contains(event.target)) {
        document.querySelector('header').classList.remove('show-menu');
      }
    });
  } else {
    // no-touch-device
    document.documentElement.classList.add('no-touch-device');
  }

  /* ================================================================================================
                                           HEADER
   * ================================================================================================= */

  document.querySelector('#header_navigation_button').addEventListener('click', () => {
    document.querySelector('header').classList.toggle('show-menu');
  });

  /* ================================================================================================
   *                                       FOOTER
   * ================================================================================================= */
  if (document.querySelector('#footer-current-year'))
    document.querySelector('#footer-current-year').innerText = currentYear;

  /* ================================================================================================
   *                                     RESEARCH PAGE INIT
   * ================================================================================================= */
  if (document.querySelector('[data-page="research"]')) {
    const showMoreButton = document.querySelector('#research-articles-more');
    const inputClearButton = document.querySelector('#research__filter_clear');
    const researchNoResult = document.querySelector('#research__no-result');
    const hash = window.location.hash.slice(1);
    const step = 6;
    let offset = step;
    let category = 'All';
    let query = '';
    let slug = null;

    let publicationCount = researchList.filter((item) => item.category === 'Publication').length;
    let blogCount = researchList.filter((item) => item.category === 'Blog').length;
    let advisoryCount = researchList.filter((item) => item.category === 'Advisory').length;
    let codeCount = researchList.filter((item) => item.category === 'Code').length;

    const templateItem = (data) => {
      return `
        <div class="article__info">
          <time>${data.date}</time>
          <h3>
            ${data.title}
          </h3>
          <div class="article__category">${data.category}</div>
        </div>
        <div class="article__description">
          ${data.description}
        </div>
      `;
    };

    /* LIST RENDER */
    const renderList = () => {
      document.querySelector('#research-articles-list').innerHTML = '';
      const list = researchList
        .filter((item) => category === 'All' || item.category === category)
        .filter((item) => {
          return slug
            ? slug === item.id
            : item.title.toLowerCase().includes(query.toLowerCase()) ||
                item.description.toLowerCase().includes(query.toLowerCase());
        });
      list.slice(0, offset).forEach((item) => {
        const section = document.createElement('section');
        section.className = 'article';
        section.innerHTML = templateItem(item);
        document.querySelector('#research-articles-list').append(section);
      });
      if (list.length === 0) {
        researchNoResult.classList.add('show');
      } else {
        researchNoResult.classList.remove('show');
      }
      if (list.length > offset) {
        showMoreButton.classList.remove('hidden');
      } else {
        showMoreButton.classList.add('hidden');
      }
      slug = null;

      const countsList = researchList.filter((item) => {
        return slug
          ? slug === item.id
          : item.title.toLowerCase().includes(query.toLowerCase()) ||
              item.description.toLowerCase().includes(query.toLowerCase());
      });

      publicationCount = countsList.filter((item) => item.category === 'Publication').length;
      blogCount = countsList.filter((item) => item.category === 'Blog').length;
      advisoryCount = countsList.filter((item) => item.category === 'Advisory').length;
      codeCount = countsList.filter((item) => item.category === 'Code').length;
      document.querySelector('#research-filter-tabs .button[data-value="Publication"] span').innerText =
        publicationCount;
      document.querySelector('#research-filter-tabs .button[data-value="Blog"] span').innerText = blogCount;
      document.querySelector('#research-filter-tabs .button[data-value="Advisory"] span').innerText = advisoryCount;
      document.querySelector('#research-filter-tabs .button[data-value="Code"] span').innerText = codeCount;
    };

    const handleFilterChange = debounce((e) => {
      query = e.target.value.trim();
      if (query.length > 0) {
        inputClearButton.classList.add('show');
      } else {
        inputClearButton.classList.remove('show');
      }

      renderList();
    }, 300);

    /* COUNTS RENDER */
    document.querySelector('#research-filter-tabs .button[data-value="Publication"] span').innerText = publicationCount;
    document.querySelector('#research-filter-tabs .button[data-value="Blog"] span').innerText = blogCount;
    document.querySelector('#research-filter-tabs .button[data-value="Advisory"] span').innerText = advisoryCount;
    document.querySelector('#research-filter-tabs .button[data-value="Code"] span').innerText = codeCount;

    /* EVENTS INIT */
    showMoreButton.addEventListener('click', () => {
      offset += step;
      renderList();
    });
    inputClearButton.addEventListener('click', () => {
      document.querySelector('#research-filter-input').value = '';
      offset = step;
      query = '';
      renderList();
      inputClearButton.classList.remove('show');
    });
    document.querySelector('#research-filter-input').addEventListener('keyup', handleFilterChange);

    document.querySelectorAll('#research-filter-tabs .button').forEach((el) => {
      el.addEventListener('click', (e) => {
        const { value } = e.currentTarget.dataset;
        category = value;
        offset = step;
        document.querySelector('#research-filter-tabs .button.contained').classList.remove('contained');
        e.currentTarget.classList.add('contained');
        renderList();
        // document.querySelector('#research-filter-input').value = '';
      });
    });
    if (hash) {
      slug = hash;
      const { title } = researchList.filter((item) => item.id === decodeURIComponent(hash))[0];
      document.querySelector('#research-filter-input').value = title;
      inputClearButton.classList.add('show');
    }
    renderList();
  }

  /* ================================================================================================
   *                                     RESEARCH PAGE INIT
   * ================================================================================================= */
  if (document.querySelector('#services-our-research')) {
    const { category } = document.querySelector('#services-our-research').dataset;
    const listRoot = document.querySelector('#services-our-research ul');
    const filteredList = researchList.filter((item) => item.service.includes(category));
    const indexesList = getRandomUniqueNumbers(0, filteredList.length - 1, 4);
    const ourResearchItem = (data) => {
      return `
          <a href="/research.html#${data.id}" class="card-item card-dark card-small">
            <h4>${data.category}</h4>
            <time>${data.date}</time>
            <p>${data.title}</p>
          </a>
      `;
    };
    indexesList.forEach((i) => {
      const li = document.createElement('li');
      li.innerHTML = ourResearchItem(filteredList[i]);
      listRoot.append(li);
    });
  }
})();
